@keyframes down {
  100% {
    background-color: "red";
    transform: translateY(30px);
  }
  0% {
    background-color: "blue";
    transform: none;
  }
}

.downButton {
  cursor: pointer;
  margin: auto;
  margin-top: 5rem;
  color: white;
  /* animation-name: down;
  background-color: "red";
  animation-play-state: unset;
  animation-duration: 2s; */
}

.downButton:hover {
  /* animation-play-state: running; */
  transition: transform 0.6s ease-out, color 0.3s ease-out;
  color: #bbbec7;
  transform: translateY(5px);
}
